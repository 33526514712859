<template>
  <div id="auth" class="auth">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="page__slide">
                        <div class="page__slide-bg"></div>
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                  <SliderComponent />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="page__welcome">
                        <h1 style="text-align: center;">Chào mừng bạn đến với <br><strong
                                style="font-weight: 600;">PWORLDSANTOSAS</strong></h1>
                        <div class="clock "><font-awesome-icon type="fas" :icon="['fas', 'calendar-alt']" /><CurrenttimeComponent /></div>
                    </div>
                    <form id="auth__form" class="auth__form" @submit.prevent="login">
                        <div class="auth__form-content">
                            <div class="auth__form-item">
                                <div class="input"><input format="alphadash" name="username" v-model="username" class="auth__form-user "
                                        id="auth__form-user" placeholder="Tên đăng nhập (bắt buộc)" value="" :class="{ 'validator__error': errors.username }"></div>
                                <div v-if="errors.username" class="validator__error">Tên đăng nhập không được bỏ trống.</div>
                            </div>
                            <div class="auth__form-item">
                                <div class="auth__form-icon">
                                    <div class="input"><input :type="passwordVisible ? 'text' : 'password'" v-model="password" name="password"
                                            class="auth__form-password " id="auth__form-password"
                                            placeholder="Mật khẩu (bắt buộc)" value="" :class="{ 'validator__error': errors.password }"></div>
                                    <font-awesome-icon :icon="['fas', passwordVisible ? 'eye-slash' : 'eye']" @click="togglePasswordVisibility"/>
                                    <div v-if="errors.password" class="validator__error">Mật khẩu không được bỏ trống.</div>
                                </div>
                            </div>
                            <div class="auth__form-item">
                              <button type="submit" class="auth__form-button btn btn__big" id="auth__form-button">
                                <span>Đăng nhập </span>
                              </button>
                            </div>
                        </div>
                        <div class="auth__form-footer"><span>Chưa có tài khoản ?</span><a href="/dang-ky">Đăng ký ngay</a></div>
                        <div class="partners">
                            <div class="partners__content"><a class="wrap__image" href="/">
                              <img src="/images/tworldsantosa-bottom.png" class=""></a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router';
import axios from 'axios';
import SliderComponent from '../components/Slider.vue';
import CurrenttimeComponent from '../components/Currenttime.vue';
import store from '../store';

export default {
  components: {
    SliderComponent,
    CurrenttimeComponent
  },
  data() {
    return {
      username: '',
      password: '',
      passwordVisible: false,
      errors: {
        username: false,
        password: false,
      },
    };
  },
  methods: {
    async login() {
      this.errors.username = !this.username.trim();
      this.errors.password = !this.password.trim();
      if (this.errors.username || this.errors.password ) {
        return;
      }
      try {
        const res = await axios.post(`${process.env.VUE_APP_API_URL}/loginuser`, {
          username: this.username,
          password: this.password
        });
        const data = res.data
        store.commit('setUserData', data.user)
        if (data.status == 1) {
          this.$toast.success(data.msg);
          localStorage.setItem('token', res.data.token);
          axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`;
          setTimeout(() => {
            router.push('/trang-chu');
          }, 1000);
        } else {
          this.$toast.error(data.msg);
        }
      } catch (err) {
        this.$toast.error('Tài khoản không tồn tại');
      }
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
  },
};
</script>

<style lang="scss" src="../assets/auth.scss"></style> 
<style lang="scss" src="../assets/input.scss"></style> 
<style lang="scss" src="../assets/button.scss"></style> 
