<template>
    <div>
       <div class="game">
          <div class="game__result">
             <div class="game__result-left">
                <a href="/tai-khoan/lich-su-cuoc"><font-awesome-icon :icon="['fas', 'arrow-up-right-dots']" /></a>
                <a href="/ket-qua/GameThreeDiceFiveMin"><font-awesome-icon :icon="['fas', 'square-poll-vertical']" /></a>
             </div>
             <div class="game__result-middle">
                <div class="game__result-head">Kết quả kỳ <span>{{ five_phien_id }}</span> hôm nay</div>
                <div class="game__result-content">
                   <div class="dice-result dice-result__big">
                      <div v-if="rolling">
                         <span class="dice-result__running" :class="getDiceClass(diceResults[0])"></span>
                         <span class="dice-result__running" :class="getDiceClass(diceResults[1])"></span>
                         <span class="dice-result__running" :class="getDiceClass(diceResults[2])"></span>
                      </div>
                      <div v-else>
                         <span class="dice-result__final" :class="getDiceClass(resulta)"></span>
                         <span class="dice-result__final" :class="getDiceClass(resultb)"></span>
                         <span class="dice-result__final" :class="getDiceClass(resultc)"></span>
                      </div>
                   </div>
                </div>
                <div class="game__result-countdown"><font-awesome-icon :icon="['fas', 'clock-rotate-left']" /><span>00:{{ minutes }}:{{ seconds }}</span></div>
             </div>
             <div class="game__result-right">
                <div v-for="(item, index) in listtype" :key="index" :class="{'active': activelisttype == item}" @click="changelisttye(item)">{{ item }}</div>
             </div>
          </div>
          <div class="game__odds">Tỷ lệ cược: gấp <span>1.98</span> - <span>192.456</span> lần</div>
          <div v-if="activelisttype === 'Chính thức'" class="game__play">
                <div class="game__play-item" tabindex="-1" style="width: 20%; display: inline-block;" :class="{ active: activechinhthuc == 'tong' }" @click="changechinhthuc('tong')">Tổng</div>
                <div class="game__play-item" tabindex="-1" style="width: 20%; display: inline-block;" :class="{ active: activechinhthuc == 'tamco' }" @click="changechinhthuc('tamco')">Tam Cô</div>
                <div class="game__play-item" tabindex="-1" style="width: 20%; display: inline-block;" :class="{ active: activechinhthuc == 'doi' }" @click="changechinhthuc('doi')">Đôi</div>
          </div>
          <div v-else class="game__play">
                <div class="game__play-item active" tabindex="-1" style="width: 20%; display: inline-block;" >Khác</div>
          </div>
          <div class="game__bets">
             <div v-if="activelisttype === 'Chính thức'" class="game__bets-item">
                <div v-if="activechinhthuc == 'tong'" class="game__bets-head"><span>Tổng</span></div>
                <div v-else-if="activechinhthuc == 'tamco'" class="game__bets-head"><span>Tam Cô</span></div>
                <div v-else-if="activechinhthuc == 'doi'"  class="game__bets-head"><span>Đôi</span></div>
                <div v-if="activechinhthuc == 'tong'" class="game__bets-content">
                   <div 
                      v-for="(item, index) in betItems.chinhthuc.tong" 
                      :key="index"
                      class="game__bet-item" 
                      :class="{ active: isActive(item) }" 
                      @click="toggleActive(item)"
                   >
                      <strong>{{ item.title }}</strong><span>{{ item.value }}</span><i v-if="isActive(item)">{{ item.money }} VND</i>
                   </div>
                </div>
                <div v-if="activechinhthuc == 'tamco'" class="game__bets-content">
                   <div 
                      v-for="(item, index) in betItems.chinhthuc.tamco" 
                      :key="index"
                      class="game__bet-item" 
                      :class="{ active: isActive(item) }" 
                      @click="toggleActive(item)"
                   >
                      <strong>{{ item.title }}</strong><span>{{ item.value }}</span><i v-if="isActive(item)">{{ item.money }} VND</i>
                   </div>
                </div>
                <div v-if="activechinhthuc == 'doi'" class="game__bets-content">
                   <div 
                      v-for="(item, index) in betItems.chinhthuc.doi" 
                      :key="index"
                      class="game__bet-item" 
                      :class="{ active: isActive(item) }" 
                      @click="toggleActive(item)"
                   >
                      <strong>{{ item.title }}</strong><span>{{ item.value }}</span><i v-if="isActive(item)">{{ item.money }} VND</i>
                   </div>
                </div>
             </div>
             <div v-else class="game__bets-item">
                <div class="game__bets-head"><span>Đôi bên</span></div>
                <div  class="game__bets-content">
                   <div 
                      v-for="(item, index) in betItems.codien.doiben" 
                      :key="index"
                      class="game__bet-item" 
                      :class="{ active: isActive(item) }" 
                      @click="toggleActive(item)"
                   >
                      <strong>{{ item.title }}</strong><span>{{ item.value }}</span><i v-if="isActive(item)">{{ item.money }} VND</i>
                   </div>
                </div>
                <div class="game__bets-head"><span>Tổng</span></div>
                <div  class="game__bets-content">
                   <div 
                      v-for="(item, index) in betItems.codien.tong" 
                      :key="index"
                      class="game__bet-item" 
                      :class="{ active: isActive(item) }" 
                      @click="toggleActive(item)"
                   >
                      <strong>{{ item.title }}</strong><span>{{ item.value }}</span><i v-if="isActive(item)">{{ item.money }} VND</i>
                   </div>
                </div>
                <div class="game__bets-head"><span>Khớp 2 số</span></div>
                <div  class="game__bets-content">
                   <div 
                      v-for="(item, index) in betItems.codien.doiben" 
                      :key="index"
                      class="game__bet-item" 
                      :class="{ active: isActive(item) }" 
                      @click="toggleActive(item)"
                   >
                      <strong>{{ item.title }}</strong><span>{{ item.value }}</span><i v-if="isActive(item)">{{ item.money }} VND</i>
                   </div>
                </div>
                <div  class="game__bets-head"><span>Tam Cô</span></div>
                <div  class="game__bets-content">
                   <div 
                      v-for="(item, index) in betItems.codien.tamco" 
                      :key="index"
                      class="game__bet-item" 
                      :class="{ active: isActive(item) }" 
                      @click="toggleActive(item)"
                   >
                      <strong>{{ item.title }}</strong><span>{{ item.value }}</span><i v-if="isActive(item)">{{ item.money }} VND</i>
                   </div>
                </div>
                <div  class="game__bets-head"><span>Đôi</span></div>
                <div  class="game__bets-content">
                   <div 
                      v-for="(item, index) in betItems.codien.doi" 
                      :key="index"
                      class="game__bet-item" 
                      :class="{ active: isActive(item) }" 
                      @click="toggleActive(item)"
                   >
                      <strong>{{ item.title }}</strong><span>{{ item.value }}</span><i v-if="isActive(item)">{{ item.money }} VND</i>
                   </div>
                </div>
                <div  class="game__bets-head"><span>Bất kỳ 1</span></div>
                <div  class="game__bets-content">
                   <div 
                      v-for="(item, index) in betItems.codien.batky" 
                      :key="index"
                      class="game__bet-item" 
                      :class="{ active: isActive(item) }" 
                      @click="toggleActive(item)"
                   >
                      <strong>{{ item.title }}</strong><span>{{ item.value }}</span><i v-if="isActive(item)">{{ item.money }} VND</i>
                   </div>
                </div>
             </div>
          </div>
       </div>
       <div class="panel">
          <div class="panel__info">
             <div class="panel__info-left">
                <div class="chips">
                   <div class="chips__item chips__item-7">Chip</div>
                </div>
             </div>
             <div class="panel__info-middle">
                <div class="input"><input min="1" type="number" pattern="[0-9]*" inputmode="numeric" name="balance" placeholder="..." v-model="chip" value="1"></div>
             </div>
             <div class="panel__info-right">
                <div class="panel__info-right-item"><span>Đã chọn: </span><strong>{{ totalActiveBets }}</strong><span> cược</span></div>
                <div class="panel__info-right-item"><span>Tổng tiền: </span><strong>{{ totalMoney }} VND</strong></div>
             </div>
          </div>
          <div class="panel__action">
             <div class="panel__action-left">
                <div class="panel__action-remove" @click="showDeleteConfirmation"><font-awesome-icon :icon="['fas', 'eraser']" /><span>Xóa</span></div>
                <div class="panel__action-cart" @click="showcart"><font-awesome-icon :icon="['fas', 'cart-shopping']" /><span>Đơn cược</span><strong>({{ totalActiveBets }})</strong></div>
             </div>
             <div class="panel__action-right">
                <div class="panel__action-submit" @click="buyfive()"><font-awesome-icon :icon="['fas', 'coins']" /><span>Đặt cược</span></div>
             </div>
          </div>
       </div>
       <div v-if="cart" class="cart animate__animated animate__bounceInLeft animate__faster">
          <div class="cart__head" @click="cart = false"><span>Giỏ cược</span><font-awesome-icon :icon="['fas', 'xmark']" /></div>
          <div v-if="activeBets.length == 0" class="nodata">
             <svg class="empty-img-default" width="184" height="152" viewBox="0 0 184 152" xmlns="http://www.w3.org/2000/svg">
                 <g fill="none" fill-rule="evenodd">
                     <g transform="translate(24 31.67)">
                     <ellipse class="empty-img-default-ellipse" cx="67.797" cy="106.89" rx="67.797" ry="12.668"></ellipse>
                     <path class="empty-img-default-path-1" d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"></path>
                     <path class="empty-img-default-path-2" d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z" transform="translate(13.56)"></path>
                     <path class="empty-img-default-path-3" d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"></path>
                     <path class="empty-img-default-path-4" d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"></path>
                     </g>
                     <path class="empty-img-default-path-5" d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"></path>
                     <g class="empty-img-default-g" transform="translate(149.65 15.383)">
                     <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815"></ellipse>
                     <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path>
                     </g>
                 </g>
             </svg>
             <span>Không có dữ liệu !</span>
          </div>
          <div v-else>
             <div class="cart__content">
                <div v-for="(item, index) in activeBets" :key="index" class="cart__item">
                   <div class="cart__item-info">
                      <div><span>Giá trị cược: </span><strong>{{ item.title }}</strong></div>
                      <div><span>Tiền cược: </span><strong>{{ item.money }} VND</strong></div>
                      <div><span>Loại cược: </span><strong>{{ item.type }}</strong></div>
                      <div><span>Tỉ lệ cược: </span><strong>{{ item.value }}</strong></div>
                      </div>
                      <div class="cart__item-balance">
                      <div class="input">
                         <input min="1" type="number" pattern="[0-9]*" inputmode="numeric" name="balance_BSum_Sum_10" placeholder="..." v-model.number="item.money"
                         @input="updateMoney(index, $event.target.value)">
                      </div>
                   </div>
                   <div class="cart__item-action">
                      <font-awesome-icon :icon="['fas', 'xmark']" @click="removeItem(index)" />
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </template>
    
 
 <script>
 import axios from 'axios';
 import Swal from 'sweetalert2';
 import store from '../store';
 
 export default {
    data() {
       return {
          cart: false,
          chip: 1,
          listtype: ['Cổ điển', 'Chính thức'],
          activelisttype: 'Chính thức',
          activeBets: [],
          activechinhthuc: 'tong',
          betItems: {
            chinhthuc: {
                tong: [],
                doi: [],
                tamco: []
            },
            codien: {
                tong: [],
                doiben: [],
                doi: [],
                tamco: [],
                batky: []
            }
         },
          seconds: '00',
          minutes: '00',
          countDown: null,
          rolling: false,
          diceResults: [1, 1, 1],
          resulta: 1,
          resultb: 2,
          resultc: 3,
          five_phien_id: 0,
       };
    },
    computed: {
       totalActiveBets() {
          return this.activeBets.length;
       },
       userData() {
             return store.getters.get_UserData;
         },
       totalMoney() {
          return this.activeBets.reduce((total, item) => {
             return total + (parseInt(item.money, 10) || 0);
          }, 0);
       }
    },
    created() {
       this.getfirst();
       this.intervalId = setInterval(this.updateDiceResults, 300);
    },
    mounted() {
       this.startCountdown();
    },
    methods: {
       changechinhthuc(item){
          this.activechinhthuc = item
       },
       async getfirst() {
          try {
             await axios.get(`${process.env.VUE_APP_API_URL}/getfirstfivemin`)
                .then(response => {
                   const data = response.data.data;
                   this.resulta = data.contenta
                   this.resultb = data.contentb
                   this.resultc = data.contentc
                   this.five_phien_id = data.five_phien_id
                   this.betItems = response.data.bets
                   store.dispatch('fetchUserData');
                });
          } catch (err) {
             console.log(err)
          }
       },
       startCountdown() {
          const date = new Date();
          const yearreal = date.getFullYear();
          const monthreal = date.getMonth() + 1;
          const dayreal = date.getDate();
          const houreal = date.getHours();
          const tet = new Date(yearreal, monthreal, dayreal, houreal, date.getMinutes() + 15, 0).getTime();
          this.countDown = setInterval(this.run, 1000, tet);
       },
       run(tet) {
          const now = new Date().getTime();
          const timeRest = tet - now;
          var minutes = Math.floor((timeRest % (1000 * 5 * 60)) / (1000));
          console.log('minutes ',minutes)
            minutes = parseInt(minutes / 60);
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
          this.minutes = minutes;
          var sec = Math.floor((timeRest % (1000 * 60)) / 1000);
          if( sec < 10 && minutes == 0){
            this.rolling = true
          }
          if (sec < 10) {
             sec = '0' + sec;
          } else {
             sec = sec - 1;
          }
          if (sec == 0 && minutes == 0) {
             console.log('alo')
             try {
                const token = localStorage.getItem('token');
                axios.get(`${process.env.VUE_APP_API_URL}/getresultfivemin/${this.five_phien_id + 1}`, {
                         headers: {
                                 Authorization: `Bearer ${token}`
                         }
                     })
                   .then(response => {
                      console.log(response.data);
                      this.rolling = false
                      const data = response.data.data;
                      this.five_phien_id = data.five_phien_id;
                      this.resulta = data.contenta
                      this.resultb = data.contentb
                      this.resultc = data.contentc
                   })
             } catch (err) {
                console.log(err)
             }
          }
          this.seconds = sec;
       },
       async buyfive() {
          if (this.activeBets == 0) {
             Swal.fire({
                title: 'Giỏ cược hiện đang trống !',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: 'Đóng',
             });
          }else if( this.totalMoney > this.userData.balance){
             Swal.fire({
                title: 'Số dư của quý khách không đủ !',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: 'Đóng',
             });
          } else {
             try {
                const token = localStorage.getItem('token');
                const response = await axios.post(`${process.env.VUE_APP_API_URL}/buyfive`, 
                {
                   cart: this.activeBets,
                   phien_id: this.five_phien_id + 1,
                   totalMoney: this.totalMoney,
                }, 
                {
                   headers: {
                         Authorization: `Bearer ${token}`
                   }
                });
                console.log('response ',response)
                const data = response.data
                if (data.status == 1) {
                   this.$toast.success(data.msg);
                   store.dispatch('fetchUserData');
                } else {
                   this.$toast.error(data.msg);
                }
             } catch (error) {
                console.log(error)
             }
          }
       },
       getDiceClass(result) {
          return `dice-result__${result}`; // Dynamically creates the class name based on result
       },
       getRandomDiceResult() {
          return Math.floor(Math.random() * 6) + 1;
       },
       updateDiceResults() {
          this.diceResults = [
             this.getRandomDiceResult(),
             this.getRandomDiceResult(),
             this.getRandomDiceResult()
          ];
       },
       updateMoney(index, newValue) {
          this.$set(this.activeBets, index, { 
             ...this.activeBets[index], 
             money: parseInt(newValue, 10) || 0 
          });
       },
       toggleActive(item) {
          const index = this.activeBets.indexOf(item);
          if (index === -1) {
             this.activeBets.push(item);
             item.money = this.chip;
          } else {
             this.activeBets.splice(index, 1);
          }
       },
       isActive(item) {
          return this.activeBets.includes(item);
       },
       removeItem(index) {
          this.activeBets.splice(index, 1);
       },
       showcart() {
          this.cart = !this.cart
       },
       showDeleteConfirmation() {
          Swal.fire({
             title: 'Bạn có thực sự muốn xóa giỏ hàng?',
             icon: 'question',
             showCancelButton: true,
             confirmButtonColor: '#00c851',
             cancelButtonColor: '#d33',
             confirmButtonText: 'Đồng ý',
             cancelButtonText: 'Đóng',
          }).then((result) => {
             if (result.isConfirmed) {
                this.activeBets = [];
             } else if (result.dismiss === Swal.DismissReason.cancel) {
                console.log('Cancelled');
             }
          });
       },
       changelisttye(item) {
          this.activelisttype = item
       },
    },
    beforeDestroy() {
       clearInterval(this.countDown);
       clearInterval(this.intervalId);
    },
 };
 </script>
 <style lang="scss" src="../assets/game.scss"></style> 
 <style lang="scss" src="../assets/no_data.scss"></style> 