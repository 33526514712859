<template>
  <div id="auth" class="auth">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="page__slide">
            <div class="page__slide-bg"></div>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <SliderComponent />
                </div>
              </div>
            </div>
          </div>
          <div class="page__welcome">
            <h1 style="text-align: center;">Chào mừng bạn đến với <br><strong
                style="font-weight: 600;">PWORLDSANTOSAS</strong></h1>
            <div class="clock "><font-awesome-icon type="fas" :icon="['fas', 'calendar-alt']" /><CurrenttimeComponent /></div>
          </div>
          <form id="auth__form" class="auth__form" @submit.prevent="register">
            <div class="auth__form-content">
              <div class="auth__form-item">
                <div class="input">
                  <input format="alphadash" name="username" v-model="username" placeholder="Tên đăng nhập (họ và tên)" value=""
                  :class="{ 'validator__error': errors.username }">
                  </div>
                  <div v-if="errors.username" class="validator__error">Tên đăng nhập không được bỏ trống.</div>
              </div>
              <div class="auth__form-item">
                <div class="input"><input format="phonevn" name="phone" v-model="phone" placeholder="Số điện thoại (bắt buộc)" value=""
                  :class="{ 'validator__error': errors.phone }">
                  <div v-if="errors.phone" class="validator__error">Số điện thoại không được bỏ trống.</div>
                </div>
              </div>
              <div class="auth__form-item">
                <div class="auth__form-icon">
                  <div class="input"><input :type="passwordVisible ? 'text' : 'password'" name="password" v-model="password" autocomplete="new-password"
                      placeholder="Mật khẩu (bắt buộc)" value="" :class="{ 'validator__error': errors.password }"></div>
                      <font-awesome-icon :icon="['fas', passwordVisible ? 'eye-slash' : 'eye']" @click="togglePasswordVisibility"/>
                  <div v-if="errors.password" class="validator__error">Mật khẩu không được bỏ trống.</div>
                </div>
              </div>
              <div class="auth__form-item">
                <div class="auth__form-icon">
                  <div class="input"><input :type="password_confirmationVisible ? 'text' : 'password'" name="password_confirmation" v-model="password_confirmation" autocomplete="new-password"
                      placeholder="Nhắc lại mật khẩu (bắt buộc)" value="" :class="{ 'validator__error': errors.password_confirmation }"></div>
                  <font-awesome-icon :icon="['fas', password_confirmationVisible ? 'eye-slash' : 'eye']" @click="togglePasswordConfirm"/>
                  <div v-if="errors.password_confirmation" class="validator__error">Nhắc lại mật khẩu không được bỏ trống.</div>
                </div>
              </div>
              <div class="auth__form-item">
                <div class="auth__form-icon">
                  <div class="input"><input :type="password_withdrawVisible ? 'text' : 'password'" name="password_withdraw" v-model="password_withdraw" autocomplete="new-password"
                      placeholder="Mật khẩu rút tiền (bắt buộc)" value="" :class="{ 'validator__error': errors.password_withdraw }"></div>
                  <font-awesome-icon :icon="['fas', password_withdrawVisible ? 'eye-slash' : 'eye']" @click="togglePasswordWithdraw"/>
                  <div v-if="errors.password_withdraw" class="validator__error">Mật khẩu rút tiền không được bỏ trống.</div>
                </div>
              </div>
              <div class="auth__form-item">
                <div class="input">
                  <input format="alphadash" name="coderef" v-model="coderef" placeholder="Mã đăng ký" value="" :class="{ 'validator__error': errors.coderef }">
                  <div v-if="errors.coderef" class="validator__error">Mã đăng ký không được bỏ trống.</div>
                </div>
              </div>
              <div class="auth__form-item">
                <button type="submit" class="auth__form-button btn btn__warning btn__big" id="auth__form-button">Đăng ký</button>
              </div>
            </div>
            <div class="auth__form-footer"><span>Đã có tài khoản ?</span><a href="/dang-nhap">Đăng nhập ngay</a></div>
            <div class="partners">
              <div class="partners__content"><a class="wrap__image" href="/"><img src="/images/tworldsantosa-bottom.png"
                    class=""></a></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SliderComponent from '../components/Slider.vue';
import CurrenttimeComponent from '../components/Currenttime.vue';
import router from '@/router';


export default {
  components: {
    SliderComponent,
    CurrenttimeComponent
  },
  data() {
    return {
      username: '',
      password: '',
      passwordVisible: false,
      password_confirmation: '',
      password_confirmationVisible: false,
      phone: '',
      password_withdraw: '',
      password_withdrawVisible: false,
      coderef: '',
      errors: {
        username: false,
        phone: false,
        password: false,
        password_confirmation: false,
        password_withdraw: false,
        coderef: false,
      },
    };
  },
  methods: {
    async register() {
      this.errors.username = !this.username.trim();
      this.errors.phone = !this.phone.trim();
      this.errors.password = !this.password.trim();
      this.errors.password_confirmation = !this.password_confirmation.trim();
      this.errors.password_withdraw = !this.password_withdraw.trim();
      this.errors.coderef = !this.coderef.trim();
      if (this.errors.username || this.errors.phone || this.errors.password || this.errors.password_confirmation || this.errors.password_withdraw || this.errors.coderef) {
        return;
      }
      try {
        const res = await axios.post(`${process.env.VUE_APP_API_URL}/registeruser`, {
          username: this.username,
          password: this.password,
          phone: this.phone,
          password_withdraw: this.password_withdraw,
          coderef: this.coderef,
        });
        console.log( res.data)
        const data = res.data
        if (data.status == 1) {
          this.$toast.success(data.msg);
          setTimeout(() => {
            router.push('/dang-nhap');
          }, 2000);
        } else {
          this.$toast.error(data.msg);
        }
      } catch (err) {
        alert(err.response.data.msg);
      }
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    togglePasswordConfirm() {
      this.password_confirmationVisible = !this.password_confirmationVisible;
    },
    togglePasswordWithdraw() {
      this.password_withdrawVisible = !this.password_withdrawVisible;
    },
  }
};
</script>

<style lang="scss" src="../assets/auth.scss"></style> 
<style lang="scss" src="../assets/input.scss"></style> 
<style lang="scss" src="../assets/button.scss"></style> 
<style lang="scss" src="../assets/validator.scss"></style> 