<template>
    <div class="tab">
        <div class="tab__container">
            <a
            v-for="(item, index) in navItems"
            :key="index"
            :href="item.href"
            :class="['tab__item', { 'tab__item active': isActive(item.href), 'cskh': item.extraClass }]"
            >
                <font-awesome-icon :icon="['fas', item.icon]" />
                <span>{{ item.text }}</span>
            </a>
        </div>
        <div class="tab__scroll-top"><i type="fas" class="fas fa-arrow-up-to-line"></i></div>
    </div>
</template>

<script>
import axios from 'axios';
import store from '../store';

export default {
  data() {
    return {
        navItems: [
          {
            icon: 'house',
            text: 'Trang chủ',
            href: '/trang-chu',
          },
          {
            icon: 'square-poll-vertical',
            text: 'Kết quả',
            href: '/ket-qua',
          },
          {
            icon: 'gift',
            text: 'Khuyến mãi',
            href: '/khuyen-mai',
            active: true,
          },
          {
            icon: 'headset',
            text: 'CSKH',
            href: '',
            extra: 'CSKH',
            extraClass: true, 
          },
          {
            icon: 'circle-user',
            text: 'Tài khoản',
            href: '/tai-khoan',
          },
        ],
        linkchat: ''
    }
  },
  created() {
      this.getfirst();
   },
  methods: {
     getfirst() {
      try {
         axios.get(`${process.env.VUE_APP_API_URL}/getlinkchat`)
          .then(response => {
            const data = response.data;
            this.navItems[3].href = data.data;
            store.commit('setLinkchat', data.data);
          });
      } catch (err) {
        console.log(err)
      }
    },
    isActive(href) {
      return this.$route.path === href;
    },
  },
}
</script>
<style lang="scss" src="../assets/tab.scss"></style> 