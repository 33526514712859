<template>
   <div class="bank">
   <div class="container">
      <div class="row">
         <div class="col-12">
            <div class="page__slide">
               <div class="page__slide-bg"></div>
               <div class="container">
                  <div class="row">
                     <div class="col-12">
                        <SliderComponent />
                     </div>
                  </div>
               </div>
            </div>
            <div class="page__welcome">
                <h1 style="text-align: center;">Chào mừng bạn đến với <br><strong style="font-weight: 600;">PWORLDSANTOSAS</strong></h1>
                <div class="clock "><font-awesome-icon type="fas" :icon="['fas', 'calendar-alt']" /><CurrenttimeComponent /></div>
            </div>
            <form v-if="userData?.bank_id == null" id="form" class="form"  @submit.prevent="addnewbank">
               <div class="form__item">
                  <div class="form__item-title">Chọn ngân hàng<span class="form__item-required">*</span></div>
                  <div class="form__item-field">
                     <div class="input">
                        <select  autocomplete="new-password" name="bank_name" v-model="bank_name" placeholder="Chọn ngân hàng" value="">
                            <option :selected="true">Chọn ngân hàng</option>
                            <option v-for="(item, index) in banks" :key="index" >
                                {{ item.value }}
                            </option>
                        </select>
                    </div>
                  </div>
               </div>
               <div class="form__item">
                  <div class="form__item-title">Chủ tài khoản<span class="form__item-required">*</span></div>
                  <div class="form__item-field">
                     <div class="input"><input format="ALPHABET" max="100" autocomplete="new-password" name="bank_user" v-model="bank_user" placeholder="Từ 3 tới 100 ký tự" value=""><span>0/100</span></div>
                  </div>
               </div>
               <div class="form__item">
                  <div class="form__item-title">Số tài khoản<span class="form__item-required">*</span></div>
                  <div class="form__item-field">
                     <div class="input"><input format="Alphadash" max="100" nospace="true" autocomplete="new-password" name="bank_number" v-model="bank_number" placeholder="Từ 3 tới 100 ký tự" value=""><span>0/100</span></div>
                  </div>
               </div>
               <div class="form__item">
                  <div class="form__item-title">CCCD Mặt trước<span class="form__item-required">*</span></div>
                  <div class="form__item-field">
                     <div class="input">
                      <input type="file" name="cccd_mt" @change="handleFileUpload">
                    </div>
                  </div>
               </div>
               <div class="form__item">
                  <div class="form__item-title">CCCD Mặt sau<span class="form__item-required">*</span></div>
                  <div class="form__item-field">
                     <div class="input">
                      <input type="file" name="cccd_ms" @change="handleFileUploadms">
                    </div>
                  </div>
               </div>
               <div class="form__item"><button class="btn btn__big" type="submit" >Cập nhật thông tin ngân hàng</button></div>
            </form>
            <form v-else id="form" class="form"  @submit.prevent="addnewbank">
               <div class="form__item">
                  <div class="form__item-title">Chọn ngân hàng<span class="form__item-required">*</span></div>
                  <div class="form__item-field">
                     <div class="input">
                        <select  autocomplete="new-password" name="bank_name"  placeholder="Chọn ngân hàng" disabled="">
                            <option selected>
                                {{ userData?.bank_id }}
                            </option>
                        </select>
                    </div>
                  </div>
               </div>
               <div class="form__item">
                  <div class="form__item-title">Chủ tài khoản<span class="form__item-required">*</span></div>
                  <div class="form__item-field">
                     <div class="input">
                      <input format="ALPHABET" max="100" autocomplete="new-password" name="bank_user" placeholder="Từ 3 tới 100 ký tự" :value="userData?.bank_user" disabled=""><span>0/100</span>
                    </div>
                  </div>
               </div>
               <div class="form__item">
                  <div class="form__item-title">Số tài khoản<span class="form__item-required">*</span></div>
                  <div class="form__item-field">
                     <div class="input">
                      <input format="Alphadash" max="100" nospace="true" autocomplete="new-password" name="bank_number" placeholder="Từ 3 tới 100 ký tự" :value="userData?.bank_number" disabled=""><span>0/100</span>
                    </div>
                  </div>
               </div>
               <div class="form__item"><button class="btn btn__big" disabled type="submit" >Cập nhật thông tin ngân hàng</button></div>
            </form>
         </div>
      </div>
   </div>
   <div class="partners">
      <div class="partners__content"><a class="wrap__image" href="/"><img src="/images/tworldsantosa-bottom.png" class=""></a></div>
   </div>
</div>
</template>

<script>
import axios from 'axios';
import SliderComponent from '../components/Slider.vue';
import CurrenttimeComponent from '../components/Currenttime.vue';
import store from '../store';
import router from '@/router';

export default {
  components: {
    SliderComponent,
    CurrenttimeComponent
  },
  computed: {
		userData() {
			return store.getters.get_UserData;
		},
	},
  data() {
    return {
        banks: [ 
            { value: 'VietinBank - Ngân hàng Thương mại cổ phần Công Thương Việt Nam' },
            { value: 'Techcombank - Ngân hàng Thương mại cổ phần Kỹ Thương Việt Nam' },
            { value: 'Vietcombank - Ngân hàng thương mại cổ phần Ngoại thương Việt Nam' },
            { value: 'VPBank - Ngân hàng Thương mại cổ phần Việt Nam Thịnh Vượng' },
            { value: 'BIDV - Ngân hàng Thương mại cổ phần Đầu tư và Phát triển Việt Nam' },
            { value: 'MB BANK - Ngân hàng Thương mại Cổ phần Quân đội' },
            { value: 'ACB - Ngân hàng thương mại cổ phần Á Châu' },
            { value: 'VIB - Ngân hàng TMCP Quốc tế Việt Nam' },
            { value: 'TPBank - Ngân hàng Thương mại Cổ phần Tiên Phong' },
            { value: 'Agribank - Ngân hàng Nông nghiệp và Phát triển Nông thôn Việt Nam' },
            { value: 'ABBANK - Ngân hàng Thương mại Cổ phần An Bình' },
            { value: 'Bảo Việt (Baoviet bank)' },
            { value: 'Bản Việt  -  Viet Capital Bank' },
            { value: 'Bắc Á - Bac A Bank' },
            { value: 'Bưu điện Liên Việt  – Lienviet Post Bank - LPB' },
            { value: 'Đại Chúng Việt Nam - PVcomBank' },
            { value: 'Đông Á' },
            { value: 'Đông Nam Á' },
            { value: 'Hàng Hải' },
            { value: 'Kiên Long' },
            { value: 'NAM A BANK' },
            { value: 'Phương Đông ' },
            { value: ' Quốc dân' }, 
            { value: 'Sài Gòn' }, 
            { value: ' Sài Gòn Công Thương' }, 
            { value: ' Sài Gòn – Hà Nội' }, 
            { value: 'Sài Gòn Thương Tín' },
            { value: 'Việt Á' },
            { value: ' Việt Nam Thương Tín' }, 
            { value: 'Xăng dầu Petrolimex' },
            { value: 'Xuất Nhập Khẩu' },
            { value: 'HDBank' },
            { value: 'GP Bank' },
            { value: 'Đại Dương' },
            { value: 'Ngân hàng TNHH MTV Xây dựng' },
            { value: 'Hong Leong Việt Nam' },
            { value: 'HSBC Việt Nam' },
            { value: 'Shinhan Việt Nam' },
            { value: 'Standard Chartered Việt Nam' },
            { value: 'Public Bank Việt Nam' },
            { value: 'CIMB Việt Nam' },
            { value: 'Ngân hàng TNHH MTV Woori Việt Nam' },
            { value: 'Ngân hàng TNHH MTV UOB Việt Nam' }
        ],
        bank_name: null,
        bank_user: null,
        bank_number: null,
        cccd_mt: null,
        cccd_ms: null,
    };
  },
  methods: {
    async addnewbank() {
      console.log('addnewbank')
      if (this.bank_name == null || this.bank_user == null || this.bank_number == null || this.cccd_mt == null || this.cccd_ms == null ) {
        return;
      }
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/addnewbank`, 
        {
            bank_name: this.bank_name,
            bank_user: this.bank_user,
            bank_number: this.bank_number,
            cccd_mt: this.cccd_mt,
            cccd_ms: this.cccd_ms,
        }, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });
        const data = response.data
        console.log(data)
        if (data.status == 1) {
          this.$toast.success(data.msg);
          setTimeout(() => {
            router.push('/tai-khoan');
          }, 1000);
        } else {
          this.$toast.error(data.msg);
        }
      } catch (err) {
        console.log(err)
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.cccd_mt = file;
    },
    handleFileUploadms(event) {
      const file = event.target.files[0];
      this.cccd_ms = file;
    },
    async withdrawmoney() {
      if( this.balance > this.userData.balance || this.balance == 0 ){
          Swal.fire({
            title: 'Số dư của quý khách không đủ !',
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Đóng',
          });
      } else{
        try {
          const token = localStorage.getItem('token');
          const response = await axios.post(`${process.env.VUE_APP_API_URL}/withdraw`, 
          {
            balance: this.balance
          }, 
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          const data = response.data
          console.log(data)
          if (data.status == 1) {
            this.$toast.success(data.msg);
          } else {
            this.$toast.error(data.msg);
          }
        } catch (err) {
          console.log(err)
        }
      }
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
  },
}
</script>

<style scoped>
.form__item select {
    background: var(--light);
    border: 1px solid var(--block);
    color: var(--default);
    width: 100%;
    flex: 0 0 100%;
    padding: 10px;
    border-radius: 10px;
}
</style>