<template>
  <div class="page__slide">
    <div class="page__slide-bg"></div>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="slide-container page__slide-content">
              <div v-if="images.length >0 && images !== undefined" class="slide-image">
                <VueSlickCarousel v-bind="slickOptions">
                  <div v-for="(image, index) in images" :key="index" class="slick-slide">
                    <div class="child">
                      <img :src="`${link}${image.link}`" class="slide-image__item" :alt="'Banner ' + (index + 1)">
                      <span v-html="image.content"></span>
                    </div>
                  </div>
                </VueSlickCarousel>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  

</template>

<script>
import axios from 'axios';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import VueSlickCarousel from 'vue-slick-carousel';

export default {
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      images: {},
      slickOptions: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true,
        swipe: true,
        autoplay: true,
        autoplaySpeed: 2000,
      },
      link: process.env.VUE_APP_API_URL
    }
  },
  created() {
    this.getfirst();
  },
  methods: {
    getfirst() {
      try {
        axios.get(`${process.env.VUE_APP_API_URL}/getlinkkhuyenmai`)
        .then(response => {
          const data = response.data;
          console.log(data)
          this.images = data.data
        });
      } catch (err) {
        console.log(err)
      }
    },
  },
};
</script>
<style lang="scss" src="../assets/slide_image.scss"></style> 
<style>
.child{
  position: relative;
}
.child span{
  z-index: 1111111;
  position: absolute;
  color: white;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -20%);
}
</style>