<template>
    <span> {{ formattedTime }}</span>
</template>

<script>
export default {
  data() {
    return {
      currentTime: new Date(),
    };
  },
  computed: {
    formattedTime() {
      return this.currentTime.toLocaleString('en-US', {
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
      });
    },
  },
  methods: {
    updateTime() {
      this.currentTime = new Date();
    },
  },
  mounted() {
    this.interval = setInterval(this.updateTime, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>