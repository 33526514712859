import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';


import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendarAlt, faArrowLeft, faEye,faCircleUser, faHeadset, faHouse,faSquarePollVertical, faGift, faRightToBracket,
  faEyeSlash,faUserCheck, faAnglesRight, faMoneyBillTrendUp, faMoneyBillTransfer, faMoneyCheckDollar, faArrowUpRightDots,
  faBuildingColumns, faUnlockKeyhole, faBell, faCircleInfo, faXmark, faEraser, faClockRotateLeft, faKhanda, faCoins, faCartShopping
  ,faHandHoldingDollar, faCircleDollarToSlot
 } from '@fortawesome/free-solid-svg-icons';
import { faDAndD } from '@fortawesome/free-brands-svg-icons';
import { faDice } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(faCalendarAlt, faArrowLeft, faEye,faCircleUser, faHeadset, faHouse,faSquarePollVertical, faGift, faRightToBracket
, faEyeSlash, faDAndD, faDice,faUserCheck,faCopy, faAnglesRight, faMoneyBillTrendUp, faMoneyBillTransfer, faMoneyCheckDollar
, faArrowUpRightDots,faBuildingColumns, faUnlockKeyhole, faBell, faCircleInfo, faXmark, faEraser, faClockRotateLeft, faKhanda, faCoins
, faCartShopping, faHandHoldingDollar, faCircleDollarToSlot );
Vue.component('font-awesome-icon', FontAwesomeIcon);


import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
Vue.use(Toast, {
  position: "top-center",
  duration: 1500,
  closeOnClick: true, 
  pauseOnHover: false,
});


Vue.config.productionTip = false


new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
