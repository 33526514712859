<template>
    <div id="auth" class="auth">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="page__slide">
                    <div class="page__slide-bg"></div>
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <SliderComponent />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="page__welcome">
                        <h1 style="text-align: center;">Chào mừng bạn đến với <br><strong style="font-weight: 600;">PWORLDSANTOSAS</strong></h1>
                        <div class="clock "><font-awesome-icon type="fas" :icon="['fas', 'calendar-alt']" /><CurrenttimeComponent /></div>
                    </div>
                    <form id="auth__form" class="auth__form" @submit.prevent="withdraw">
                    <div class="auth__form-content">
                        <div class="auth__form-item">
                            <div class="auth__form-icon">
                                <div class="input">
                                    <input :type="passwordVisible ? 'text' : 'password'" name="password_withdraw" v-model="password_withdraw" autocomplete="new-password" placeholder="Mật khẩu rút tiền cũ (bắt buộc)" value=""
                                    :class="{ 'validator__error': errors.password_withdraw }">
                                </div>
                                <div v-if="errors.password_withdraw" class="validator__error">Mật khẩu không được bỏ trống.</div>
                                <i type="fas" class="fas fa-eye"></i>
                            </div>
                        </div>
                        <div class="auth__form-item">
                            <div class="auth__form-icon">
                                <div class="input">
                                    <input :type="passwordWithVisible ? 'text' : 'password'" name="password_withdraw_new" v-model="password_withdraw_new" autocomplete="new-password" placeholder="Mật khẩu rút tiền mới (bắt buộc)" value=""
                                    :class="{ 'validator__error': errors.password_withdraw_new }">
                                </div>
                                <div v-if="errors.password_withdraw_new" class="validator__error">Mật khẩu không được bỏ trống.</div>
                                <i type="fas" class="fas fa-eye"></i>
                            </div>
                        </div>
                        <div class="auth__form-item"><button type="submit" class="auth__form-button btn btn__warning btn__big" id="auth__form-button">Cập nhật</button></div>
                    </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="partners">
            <div class="partners__content"><a class="wrap__image" href="/"><img src="/images/tworldsantosa-bottom.png" class=""></a></div>
        </div>
        </div>
</template>


<script>
import axios from 'axios';
import SliderComponent from '../components/Slider.vue';
import CurrenttimeComponent from '../components/Currenttime.vue';
import store from '../store';

export default {
  components: {
    SliderComponent,
    CurrenttimeComponent
  },
  data() {
    return {
        password_withdraw: '',
        password_withdraw_new: '',
        passwordVisible: false,
        passwordWithVisible: false,
        errors: {
            password_withdraw: false,
            password_withdraw_new: false,
        },
    };
  },
  computed: {
		userData() {
			return store.getters.get_UserData;
		}
	},
  methods: {
    async withdraw() {
      this.errors.password_withdraw = !this.password_withdraw.trim();
      this.errors.password_withdraw_new = !this.password_withdraw_new.trim();
      if (this.errors.password_withdraw || this.errors.password_withdraw_new) {
        return;
      }
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/changepasswordwithdraw`, 
        {
            password_withdraw: this.password_withdraw,
            password_withdraw_new: this.password_withdraw_new
        }, 
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const data = response.data
        console.log(data)
        if (data.status == 1) {
          this.$toast.success(data.msg);
        } else {
          this.$toast.error(data.msg);
        }
      } catch (err) {
        console.log(err)
      }
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
  },
}
</script>

<style lang="scss" src="../assets/notify.scss"></style> 