<template>
    <div class="slide-container page__slide-content">
        <div class="slide-image">
               <VueSlickCarousel v-bind="slickOptions">
                <div v-for="(image, index) in images" :key="index" class="slick-slide">
                  <div>
                    <img :src="image.src" class="slide-image__item" :alt="'Banner ' + (index + 1)">
                  </div>
                </div>
              </VueSlickCarousel>
        </div>
    </div>
</template>

<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import VueSlickCarousel from 'vue-slick-carousel';

export default {
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      images: [
        { src: '/images/banner_4.jpg' },
        { src: '/images/banner_1.jpg' },
        { src: '/images/banner_2.jpg' },
        { src: '/images/banner_3.jpg' },
      ],
      slickOptions: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true,
        swipe: true,
        autoplay: true,
        autoplaySpeed: 1000,
      },
    };
  },
};
</script>

<style lang="scss" src="../assets/slide_image.scss"></style> 
