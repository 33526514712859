import { render, staticRenderFns } from "./Register.vue?vue&type=template&id=ed0f2ee2"
import script from "./Register.vue?vue&type=script&lang=js"
export * from "./Register.vue?vue&type=script&lang=js"
import style0 from "../assets/auth.scss?vue&type=style&index=0&prod&lang=scss&external"
import style1 from "../assets/input.scss?vue&type=style&index=1&prod&lang=scss&external"
import style2 from "../assets/button.scss?vue&type=style&index=2&prod&lang=scss&external"
import style3 from "../assets/validator.scss?vue&type=style&index=3&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports