<template>
    <div class="result-game">
        <div v-for="(item, index) in datalist" :key="index" class="result-game__item">
            <div class="result-game__item-row">
                <span>Kỳ: {{ item.three_phien_id }}</span>
                <div class="dice-result">
                    <div>
                        <span :class="getDiceClass(item.contenta)"></span>
                        <span :class="getDiceClass(item.contentb)"></span>
                        <span :class="getDiceClass(item.contentc)"></span>
                    </div>
                </div>
            </div>
            <div class="result-game__item-row">
                <span>Bắt đầu: {{ formatDate(item.createdAt) }}</span>
                <span>Kết thúc: {{ formatDate(item.createdAt) }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            datalist: []
        }
    },
    created() {
        this.getfirst();
    },
    methods: {
        async getfirst() {
            try {
                const token = localStorage.getItem('token');
                await axios.get(`${process.env.VUE_APP_API_URL}/getfirstketquathree`,
                        {
                        headers: {
                                Authorization: `Bearer ${token}`
                        }
                    })
                    .then(response => {
                        const data = response.data.data;
                        console.log(data)
                        this.datalist = data
                    });
            } catch (err) {
                console.log(err)
            }
        },
        getDiceClass(result) {
            return `dice-result__${result}`; // Dynamically creates the class name based on result
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const formattedDate = date.toLocaleString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false, 
                timeZone: 'Asia/Ho_Chi_Minh' 
            });
            return formattedDate.replace(',', '');
        }
    }
}
</script>
<style lang="scss" src="../../assets/result.scss"></style> 