import Vue from 'vue';
import Router from 'vue-router';
import Register from '../components/Register.vue';
import Login from '../components/Login.vue';
import Dashboard from '@/components/Dashboard.vue';
import Khuyenmai from '@/components/Khuyenmai.vue';
import Taikhoan from '@/components/Taikhoan.vue';
import Ruttien from '@/components/Ruttien.vue';
import Nganhang from '@/components/Nganhang.vue';
import GameThreeDiceOneMin from '@/components/GameThreeDiceOneMin.vue';
import KetquaOne from '@/components/ketqua/GameThreeDiceOneMin.vue';
import GameThreeDiceThreeMin from '@/components/GameThreeDiceThreeMin.vue';
import KetquaThree from '@/components/ketqua/GameThreeDiceThreeMin.vue';

import GameThreeDiceFiveMin from '@/components/GameThreeDiceFiveMin.vue';
import KetquaFive from '@/components/ketqua/GameThreeDiceFiveMin.vue';

import HistoryBet from '@/components/HistoryBet.vue';
import Biendongsodu from '@/components/Biendongsodu.vue';
import Historydesposit from '@/components/Historydesposit.vue';
import Historywithdraw from '@/components/Historywithdraw.vue';
import Changepasswordwithdraw from '@/components/Changepasswordwithdraw.vue';
import Changepassword from '@/components/Changepassword.vue';
import Thongbao from '@/components/Thongbao.vue';
import Vechungtoi from '@/components/Vechungtoi.vue';
import Ketqua from '@/components/Ketqua.vue';
import store from '../store';

Vue.use(Router);

const routes = [
  { path: '/', redirect: '/trang-chu' },
  { path: '/dang-ky', component: Register },
  { path: '/dang-nhap', component: Login },
  { path: '/khuyen-mai', component: Khuyenmai },
  { path: '/trang-chu', component: Dashboard },
  { path: '/tai-khoan', component: Taikhoan, meta: { requiresAuth: true } },
  { path: '/tai-khoan/rut-tien', component: Ruttien, meta: { requiresAuth: true } },
  { path: '/tai-khoan/ngan-hang', component: Nganhang, meta: { requiresAuth: true } },
  
  { path: '/tro-choi/GameThreeDiceOneMin', component: GameThreeDiceOneMin, meta: { requiresAuth: true } },
  { path: '/ket-qua/GameThreeDiceOneMin', component: KetquaOne, meta: { requiresAuth: true } },

  { path: '/tro-choi/GameThreeDiceThreeMin', component: GameThreeDiceThreeMin, meta: { requiresAuth: true } },
  { path: '/ket-qua/GameThreeDiceThreeMin', component: KetquaThree, meta: { requiresAuth: true } },

  { path: '/tro-choi/GameThreeDiceFiveMin', component: GameThreeDiceFiveMin, meta: { requiresAuth: true } },
  { path: '/ket-qua/GameThreeDiceFiveMin', component: KetquaFive, meta: { requiresAuth: true } },

  { path: '/tai-khoan/lich-su-cuoc', component: HistoryBet, meta: { requiresAuth: true } },
  { path: '/tai-khoan/bien-dong-so-du', component: Biendongsodu, meta: { requiresAuth: true } },
  { path: '/tai-khoan/nap-tien/lish-su', component: Historydesposit, meta: { requiresAuth: true } },
  { path: '/tai-khoan/rut-tien/lish-su', component: Historywithdraw, meta: { requiresAuth: true } },
  { path: '/tai-khoan/doi-mat-khau-rut-tien', component: Changepasswordwithdraw, meta: { requiresAuth: true } },
  { path: '/tai-khoan/doi-mat-khau', component: Changepassword, meta: { requiresAuth: true } },
  { path: '/thong-bao', component: Thongbao, meta: { requiresAuth: true } },
  { path: '/ve-chung-toi', component: Vechungtoi, meta: { requiresAuth: true } },
  { path: '/ket-qua', component: Ketqua, meta: { requiresAuth: true } },
];

const router = new Router({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  document.title = 'PWORLDSANTOSAS';
  const token = localStorage.getItem('token');
  store.dispatch('fetchUserData');
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      next('/dang-nhap'); 
    } else {
      next(); 
    }
  } else {
    next(); 
  }
});

export default router;