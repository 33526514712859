import { render, staticRenderFns } from "./Khuyenmai.vue?vue&type=template&id=26cba0c6"
import script from "./Khuyenmai.vue?vue&type=script&lang=js"
export * from "./Khuyenmai.vue?vue&type=script&lang=js"
import style0 from "../assets/slide_image.scss?vue&type=style&index=0&prod&lang=scss&external"
import style1 from "./Khuyenmai.vue?vue&type=style&index=1&id=26cba0c6&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports