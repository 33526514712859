<template>
    <div>
      <button @click="triggerAlert"></button>
    </div>
  </template>
  
  <script>
  import Swal from 'sweetalert2';
  
  export default {
    name: 'SweetAlert',
    props: {
      text: {
        type: String,
        required: true
      },
      confirmButtonText: {
        type: String,
        default: 'OK'
      }
    },
    created() {
        this.triggerAlert();
    },
    methods: {
      triggerAlert() {
        Swal.fire({
            title: this.text,
          confirmButtonText: this.confirmButtonText,
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Đóng',
        });
      }
    }
  };
  </script>

<style lang="scss" src="../assets/func.scss"></style> 