<template>
    <div class="withdraw">
      <div class="container">
          <div class="row">
              <div class="col-12">
                  <div class="page__slide">
                  <div class="page__slide-bg"></div>
                  <div class="container">
                      <div class="row">
                          <div class="col-12">
                              <SliderComponent />
                          </div>
                      </div>
                  </div>
                  </div>
                  <div class="page__welcome">
                  <h1 style="text-align: center;">Chào mừng bạn đến với <br><strong style="font-weight: 600;">PWORLDSANTOSAS</strong></h1>
                  <div class="clock "><font-awesome-icon type="fas" :icon="['fas', 'calendar-alt']" /><CurrenttimeComponent /></div>
                  </div>
                  <form v-if="start" id="form" class="form" @submit.prevent="withdraw">
                    <div class="form__item">
                        <div class="form__item-title">Vui lòng nhập mật khẩu rút tiền để tiếp tục:<span class="form__item-required">*</span></div>
                        <div class="form__item-field">
                            <div class="input">
                                <input :type="passwordVisible ? 'text' : 'password'" name="password_withdraw_verify"  v-model="password" autocomplete="new-password" placeholder="Mật khẩu rút tiền (bắt buộc)" value=""
                                :class="{ 'validator__error': errors.password }">
                            </div>
                            <font-awesome-icon :icon="['fas', passwordVisible ? 'eye-slash' : 'eye']" @click="togglePasswordVisibility"/>
                            <div v-if="errors.password" class="validator__error">Mật khẩu không được bỏ trống.</div>
                        </div>
                    </div>
                    <div class="form__item"><button class="btn btn__big" type="submit">Xác thực</button></div>
                  </form>

                  <form v-else id="form" class="form"  @submit.prevent="withdrawmoney">
                    <div class="form__item">
                        <div class="form__item-title">Nhập số tiền rút<span class="form__item-required">*</span><span>(Tối đa: 0 VND)</span></div>
                        <div class="form__item-field">
                          <div class="input">
                            <input placeholder="Nhập số tiền..." name="balance" v-model="balance" class="" type="text" value=" đ" inputmode="numeric">
                          </div>
                          <i type="fas" class="fas fa-keyboard"></i>
                        </div>
                        <div class="form__item-field">
                          <div class="chips disabled">
                              <div class="chips__item chips__item-1">100k</div>
                              <div class="chips__item chips__item-2">200k</div>
                              <div class="chips__item chips__item-3">500k</div>
                              <div class="chips__item chips__item-4">1M</div>
                              <div class="chips__item chips__item-5">2M</div>
                              <div class="chips__item chips__item-6">5M</div>
                              <div class="chips__item chips__item-7">10M</div>
                          </div>
                        </div>
                    </div>
                    <div v-if="userData?.bank_id == null" class="notify notify__default" style="margin-bottom: 0px;">Quý khách chưa cập nhật đầy đủ thông tin ngân hàng ! <br>Vui lòng cập nhật đẩy đủ để tiến hành rút tiền.<br></div>
                    <div v-if="userData?.bank_id != null" class="form__item">
                      <div class="bank-info">
                          <div class="bank-info__title fw__bold">Tài khoản ngân hàng thụ hưởng:</div>
                          <div class="bank-info__item">
                            <p>Ngân hàng:</p>
                            <strong>{{ userData?.bank_id }}</strong>
                          </div>
                          <div class="bank-info__item">
                            <p>Chủ tài khoản:</p>
                            <strong>{{ userData?.bank_user }}</strong>
                          </div>
                          <div class="bank-info__item">
                            <p>Số tài khoản:</p>
                            <strong>*********{{ userData?.bank_number.toString().slice(-3) }}</strong>
                          </div>
                          <div class="bank-info__item">
                            <p>Số tiền rút:</p>
                            <strong>{{ balance }} VND</strong>
                          </div>
                          <div class="bank-info__note">*Số tiền có thể rút (CƯỢC HỢP LỆ) tài khoản cần cá cược 80% số tiền nạp mới được rút tiền. <br>*MIN rút 500,000VND, MAX rút 9,999,999,999VND <br>*Mỗi ngày tối thiểu rút tiền 2 lần <br>*Chi tiết vui lòng liên hệ bộ phận CSKH</div>
                      </div>
                    </div>
                    <div v-if="userData?.bank_id != null" class="form__item"><button class="btn btn__big" type="submit"  readonly="">Gửi yêu cầu rút tiền</button></div>

                  </form>

              </div>
          </div>
      </div>
      <div class="partners">
          <div class="partners__content"><a class="wrap__image" href="/"><img src="/images/tworldsantosa-bottom.png" class=""></a></div>
      </div>
    </div>
</template>

<script>
import axios from 'axios';
import SliderComponent from '../components/Slider.vue';
import CurrenttimeComponent from '../components/Currenttime.vue';
import store from '../store';
import Swal from 'sweetalert2';

export default {
  components: {
    SliderComponent,
    CurrenttimeComponent
  },
  data() {
    return {
        password: '',
        passwordVisible: false,
        errors: {
          password: false,
          balance: false,
        },
        start: true,
        balance: 0
    };
  },
  computed: {
		userData() {
			return store.getters.get_UserData;
		}
	},
  methods: {
    async withdraw() {
      this.errors.password = !this.password.trim();
      if (this.errors.password ) {
        return;
      }
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/rut-tien`, 
        {
          password: this.password
        }, 
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const data = response.data
        console.log(data)
        if (data.status == 1) {
          // this.$toast.success(data.msg);
          this.start= false
        } else {
          this.$toast.error(data.msg);
        }
      } catch (err) {
        console.log(err)
      }
    },
    async withdrawmoney() {
      if( this.balance > this.userData.balance || this.balance == 0 ){
          Swal.fire({
            title: 'Số dư của quý khách không đủ !',
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Đóng',
          });
      } else{
        try {
          const token = localStorage.getItem('token');
          const response = await axios.post(`${process.env.VUE_APP_API_URL}/withdraw`, 
          {
            balance: this.balance
          }, 
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          const data = response.data
          console.log(data)
          if (data.status == 1) {
            this.$toast.success(data.msg);
          } else {
            this.$toast.error(data.msg);
          }
        } catch (err) {
          console.log(err)
        }
      }
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
  },
}
</script>

<style lang="scss" src="../assets/notify.scss"></style> 