<template>
    <div class="account">
        <div class="account__user">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                    <div class="account__user-container">
                        <div class="account__user-info">
                            <img src="/images/users/car (31).jpg" alt="">
                            <div><strong><font-awesome-icon :icon="['fas', 'user-check']" />{{ userData?.username }}<font-awesome-icon :icon="['far', 'copy']" /></strong>
                                <span>Tham gia từ {{ formattedDate(userData?.createdAt) }}</span></div>
                        </div>
                        <div class="account__user-vip">
                            <a class="wrap__image" href="/">
                                <img :src="'images/vips/vip' + (userData?.vip + 1) + '.png'">
                            </a>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="account__money">
            <div class="account__money-balance"><strong>Số dư tài khoản</strong><span>{{ userData?.balance.toLocaleString('en-US', { minimumFractionDigits: 2 }) }} VND</span></div>
            <div class="account__money-link">
                <a class="account__money-item" style="position: relative;" :href="Linkchat">
                    <font-awesome-icon :icon="['fas', 'hand-holding-dollar']" />
                    <span>Nạp tiền</span></a>
                <span></span><a class="account__money-item" href="/tai-khoan/rut-tien">
                    <font-awesome-icon :icon="['fas', 'circle-dollar-to-slot']" />
                    <span>Rút tiền</span></a>
            </div>
        </div>
        <div class="account__menu">
            <div class="account__menu-item"><font-awesome-icon :icon="['fas', 'arrow-up-right-dots']" /><a class="account__money-item" href="/tai-khoan/lich-su-cuoc">Lịch sử đặt cược</a><font-awesome-icon :icon="['fas', 'angles-right']" /></div>
            <div class="account__menu-item"><font-awesome-icon :icon="['fas', 'money-check-dollar']" /><a class="account__money-item" href="/tai-khoan/bien-dong-so-du">Biến động số dư</a><font-awesome-icon :icon="['fas', 'angles-right']" /></div>
            <div class="account__menu-item"><font-awesome-icon :icon="['fas', 'money-bill-trend-up']" /><a class="account__money-item" href="/tai-khoan/nap-tien/lish-su">Lịch sử nạp tiền</a><font-awesome-icon :icon="['fas', 'angles-right']" /></div>
            <div class="account__menu-item"><font-awesome-icon :icon="['fas', 'money-bill-transfer']" /><a class="account__money-item" href="/tai-khoan/rut-tien/lish-su">Lịch sử rút tiền</a><font-awesome-icon :icon="['fas', 'angles-right']" /></div>
            <div class="account__menu-item"><font-awesome-icon :icon="['fas', 'building-columns']" /><a class="account__money-item" href="/tai-khoan/ngan-hang">Liên kết ngân hàng</a><font-awesome-icon :icon="['fas', 'angles-right']" /></div>
            <div class="account__menu-item"><font-awesome-icon :icon="['fas', 'unlock-keyhole']" /><a class="account__money-item" href="/tai-khoan/doi-mat-khau-rut-tien">Đổi mật khẩu rút tiền</a><font-awesome-icon :icon="['fas', 'angles-right']" /></div>
            <div class="account__menu-item"><font-awesome-icon :icon="['fas', 'unlock-keyhole']" /><a class="account__money-item" href="/tai-khoan/doi-mat-khau">Đổi mật khẩu</a><font-awesome-icon :icon="['fas', 'angles-right']" /></div>
            <div class="account__menu-item"><font-awesome-icon :icon="['fas', 'bell']" /><a class="account__money-item" href="/thong-bao">Trung tâm thông báo</a><font-awesome-icon :icon="['fas', 'angles-right']" /></div>
            <div class="account__menu-item"><font-awesome-icon :icon="['fas', 'circle-info']" /><a class="account__money-item" href="/ve-chung-toi">Về chúng tôi</a><font-awesome-icon :icon="['fas', 'angles-right']" /></div>
        </div>
        <div class="account__logout" @click="logout()">Đăng xuất</div>
        <div class="partners">
            <div class="partners__content"><a class="wrap__image" href="/"><img src="/images/tworldsantosa-bottom.png" class=""></a></div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import store from '../store';
import router from '@/router';

export default {
  data() {
    return {

    }
  },
  computed: {
		userData() {
			return store.getters.get_UserData;
		},
		Linkchat() {
			return store.getters.get_Linkchat;
		}
	},
  methods: {
    async logout(){
        await store.dispatch('handleLogout');
        router.push('/trang-chu'); 
    },
    formattedDate(item) {
      const date = new Date(item);
      const day = String(date.getDate()).padStart(2, '0'); // Ensures two digits
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      const year = date.getFullYear();
      return `${day}/${month}/${year}`; // Formats as d/m/y
    }
  }
}
</script>
<style lang="scss" src="../assets/account.scss"></style> 