<template>
    <div :heading="title" class="header">
        <a class="header__left" title="Quay lại" @click="goBack"><font-awesome-icon :icon="['fas', 'arrow-left']" /></a>

		<div v-if="$route.path == '/trang-chu'" class="header__middle">
			<div title="Logo" src="/images/tworldsantosa.png" class="logo " id="logo"><a href="/"><img src="/images/tworldsantosa.png" alt="Logo" title="Logo"></a></div>
		</div>
		<div v-if="$route.path == '/tro-choi/GameThreeDiceOneMin'" class="game-head__middle"><h3>Nhanh 3 1phút</h3></div>
		<div v-else-if="$route.path == '/tro-choi/GameThreeDiceThreeMin'" class="game-head__middle"><h3>Nhanh 3 3phút</h3></div>
		<div v-else-if="$route.path == '/tro-choi/GameThreeDiceFiveMin'" class="game-head__middle"><h3>Nhanh 3 5phút</h3></div>
		<div v-else class="header__heading">
            <h3>{{ title }}</h3>
        </div>

        <a class="header__right" href="/trang-chu"></a>

		<a v-if="userData !== null" class="game-head__right" href="/tai-khoan">
			<div class="game-head__right-info"><div>
				<img src="/images/vips/vip1.png" alt="" style="height: 15px;"><strong>{{ userData.username }}</strong></div><span><i>Số dư: </i>{{ userData?.balance.toLocaleString('en-US', { minimumFractionDigits: 2 }) }} VND</span>
			</div><img src="/images/users/car (31).jpg" alt="">
		</a>
    </div>
</template>
<script>
import store from '../store';

export default {
	data() {
		return {
			title : ''
		}
	},
	computed: {
		userData() {
			return store.getters.get_UserData;
		}
	},
	created() {
		this.gettitle(); 
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		gettitle(){
			const href = this.$route.path
			if(href == '/dang-nhap'){
				this.title = 'Đăng nhập tài khoản';
			}else if(href == '/dang-ky'){
				this.title = 'Đăng ký tài khoản';
			}else if(href == '/khuyen-mai'){
				this.title = 'Khuyến mãi';
			}else if(href == '/tai-khoan'){
				this.title = 'Trung tâm thành viên';
			}else if(href == '/ket-qua/GameThreeDiceOneMin'){
				this.title = 'Nhanh 3 1phút';
			}else if(href == '/tai-khoan/lich-su-cuoc'){
				this.title = 'Lịch sử đặt cược';
			}else if(href == '/tai-khoan/ngan-hang'){
				this.title = 'Tài khoản ngân hàng';
			}else if(href == '/tai-khoan/bien-dong-so-du'){
				this.title = 'Biến động số dư';
			}else if(href == '/tai-khoan/nap-tien/lish-su'){
				this.title = 'Lịch sử nạp tiền';
			}else if(href == '/tai-khoan/doi-mat-khau-rut-tien'){
				this.title = 'Đổi mật khẩu rút tiền';
			}else if(href == '/tai-khoan/doi-mat-khau'){
				this.title = 'Đổi mật khẩu';
			}else if(href == '/thong-bao'){
				this.title = 'Thông báo';
			}else if(href == '/ve-chung-toi'){
				this.title = 'Về chúng tôi';
			}else if(href == '/tai-khoan/rut-tien'){
				this.title = 'Rút tiền';
			}else if(href == '/tai-khoan/rut-tien/lish-su'){
				this.title = 'Lịch sử rút tiền';
			}else if(href == '/ket-qua'){
				this.title = 'Kết quả';
			}else if(href == '/ket-qua/GameThreeDiceThreeMin'){
				this.title = 'Nhanh 3 3phút';
			}else if(href == '/ket-qua/GameThreeDiceFiveMin'){
				this.title = 'Nhanh 3 5phút';
			}
		}
	}
};
</script>

<style lang="scss" src="../assets/game.scss"></style> 
<style scoped>
.header {
	 position: fixed;
	 top: 0;
	 left: 0;
	 width: 100vw;
	 z-index: 1000;
	 display: flex;
	 justify-content: space-between;
	 align-items: center;
	 background: var(--main);
	 color: var(--light);
}
 .header * {
	 color: var(--light);
}
 .header__heading {
	 height: 50px;
	 width: calc(100% - 50px - 50px);
	 flex: 0 0 calc(100% - 50px - 50px);
	 display: flex;
	 justify-content: center;
	 align-items: center;
}
 .header__heading h3 {
	 width: 100%;
	 flex: 0 0 100%;
	 display: block;
	 white-space: nowrap;
	 text-overflow: ellipsis;
	 overflow: hidden;
	 font-size: 15px;
	 font-weight: 500;
	 text-transform: uppercase;
	 text-align: center;
}
 .header__left {
	 height: 50px;
	 width: 50px;
	 flex: 0 0 50px;
	 display: flex;
	 justify-content: center;
	 align-items: center;
}
 .header__left i {
	 font-size: 18px;
	 color: var(--light);
	 position: relative;
}
 .header__left i span {
	 position: absolute;
	 top: -9px;
	 left: 70%;
	 height: 18px;
	 line-height: 18px;
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 padding: 0 3px 0 3px;
	 border-radius: 100%;
	 font-size: 11px;
	 font-weight: 600;
	 color: var(--block);
	 white-space: nowrap;
	 min-width: 17px;
}
 .header__left:hover i {
	 color: var(--hover);
}
 .header__left:hover i span {
	 color: var(--hover);
}
 .header__middle {
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 height: 50px;
	 width: calc(100% - 50px - 50px);
	 flex: 0 0 calc(100% - 50px - 50px);
}
 @media (max-width: 320px) {
	 .header__middle {
		 display: none;
	}
}
 .header__middle .logo {
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 height: 100%;
}
 @media (max-width: 576px) {
	 .header__middle .logo {
		 max-width: 140px;
	}
}
 .header__middle .logo a {
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 width: 100%;
	 height: 100%;
	 max-width: 100%;
	 max-height: 100%;
}
 @media (max-width: 320px) {
	 .header__middle .logo a {
		 padding-left: 0;
	}
}
 .header__right {
	 height: 50px;
	 width: 50px;
	 flex: 0 0 50px;
	 display: flex;
	 justify-content: center;
	 align-items: center;
}
 .header__right i {
	 font-size: 20px;
	 color: var(--light);
}
 .header__right > img {
	 width: 38px;
	 height: 38px;
	 border-radius: 100%;
	 object-fit: cover;
	 border: 2px solid var(--hover);
	 margin-right: 15px;
}
 @media (max-width: 480px) {
	 .header__right > img {
		 margin-right: 13px;
	}
}
 .header__right:hover i {
	 color: var(--hover);
}
 .header__right #root__right-info {
	 display: flex;
	 flex-direction: column;
	 justify-content: flex-end;
	 align-items: flex-end;
	 margin: 0 5px;
}
 .header__right #root__right-info strong {
	 font-size: 13px;
	 font-weight: 400;
	 color: var(--light);
	 line-height: 15px;
	 display: inline-block;
	 max-width: 90px;
	 text-overflow: ellipsis;
	 white-space: nowrap;
	 overflow: hidden;
}
 .header__right #root__right-info span {
	 font-weight: 400;
	 font-size: 12px;
	 line-height: 14px;
	 max-width: 145px;
	 white-space: nowrap;
	 text-overflow: ellipsis;
	 overflow: hidden;
}
 .header__right #root__right-info span i {
	 font-weight: 400;
	 font-size: 12px;
	 line-height: 14px;
	 white-space: nowrap;
	 text-overflow: ellipsis;
	 overflow: hidden;
	 font-style: normal;
}
 @media (max-width: 320px) {
	 .header__right #root__right-info span i {
		 display: none;
	}
}
 .header__logged {
	 justify-content: initial;
}
 .header__logged #root__middle {
	 width: fit-content;
	 flex: 0 0 fit-content;
}
 .header__logged #root__right {
	 width: fit-content;
	 flex: 0 0 fit-content;
	 min-width: 50px;
	 margin-left: auto;
}
 
</style>