import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

const store = new Vuex.Store({
  state() {
    return {
      userData: null,
      linkchat: '',
    };
  },
  mutations: {
    setUserData(state, data) {
      state.userData = data;
    },
    clearUserData(state) {
      state.userData = null;
    },
    setLinkchat(state, data) {
      state.linkchat = data;
    },
  },
  getters: {
    get_UserData(state, data) {
      return state.userData;
    },
    get_Linkchat(state, data) {
      return state.linkchat;
    },
  },
  actions: {
    async fetchUserData ({ commit }) {
      try {
        const token = localStorage.getItem('token');
        if(token !== undefined){
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/user`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          commit('setUserData', response.data.user);
        }
      } catch (error) {
        localStorage.removeItem('token');
        commit('clearUserData', null); 
        console.log('Failed to logout:', error);
      }
    },
    async handleLogout({ commit }) {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No token found');
        localStorage.removeItem('token');
        commit('clearUserData', null); 
        let ls = localStorage
      } catch (error) {
        console.log('Failed to logout:', error);
      }
    },
  }
});

export default store;