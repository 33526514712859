<template>
  <main id="root" class="main">
   <section class="screen">
      <HeaderComponent />
      <router-view></router-view>
   </section>
   <div class="Toastify"></div>
   <TabComponent />
</main>
</template>

<script>
import HeaderComponent from './components/Header.vue';
import TabComponent from './components/Tab.vue';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    TabComponent
  }
};
</script>

<style lang="scss" src="./assets/global.scss"></style> 
<style lang="scss" src="./assets/master.scss"></style> 